import(/* webpackMode: "eager" */ "C:\\www\\node\\globalredex\\node_modules\\@mui\\icons-material\\Check.js");
import(/* webpackMode: "eager" */ "C:\\www\\node\\globalredex\\node_modules\\@mui\\icons-material\\WhatsApp.js");
import(/* webpackMode: "eager" */ "C:\\www\\node\\globalredex\\node_modules\\@mui\\material\\node\\Box\\index.js");
import(/* webpackMode: "eager" */ "C:\\www\\node\\globalredex\\node_modules\\@mui\\material\\node\\Button\\index.js");
import(/* webpackMode: "eager" */ "C:\\www\\node\\globalredex\\node_modules\\@mui\\material\\node\\Fab\\index.js");
import(/* webpackMode: "eager" */ "C:\\www\\node\\globalredex\\node_modules\\@mui\\material\\node\\Paper\\index.js");
import(/* webpackMode: "eager" */ "C:\\www\\node\\globalredex\\node_modules\\@mui\\material\\node\\Stack\\index.js");
import(/* webpackMode: "eager" */ "C:\\www\\node\\globalredex\\node_modules\\@mui\\material\\node\\Typography\\index.js");
import(/* webpackMode: "eager" */ "C:\\www\\node\\globalredex\\node_modules\\next\\dist\\client\\image-component.js");
import(/* webpackMode: "eager" */ "C:\\www\\node\\globalredex\\node_modules\\next\\dist\\client\\link.js");
import(/* webpackMode: "eager" */ "C:\\www\\node\\globalredex\\node_modules\\next\\dist\\shared\\lib\\lazy-dynamic\\dynamic-no-ssr.js");
import(/* webpackMode: "eager" */ "C:\\www\\node\\globalredex\\src\\components\\aboutUs\\Contact.tsx");
import(/* webpackMode: "eager" */ "C:\\www\\node\\globalredex\\src\\components\\aboutUs\\OurTeam.tsx");
import(/* webpackMode: "eager" */ "C:\\www\\node\\globalredex\\src\\components\\common\\ElevationScroll.tsx");
import(/* webpackMode: "eager" */ "C:\\www\\node\\globalredex\\src\\components\\common\\RevealAnimation.tsx");
import(/* webpackMode: "eager" */ "C:\\www\\node\\globalredex\\src\\components\\common\\ScrollTop.tsx");
import(/* webpackMode: "eager" */ "C:\\www\\node\\globalredex\\src\\components\\home\\Banner.tsx");
import(/* webpackMode: "eager" */ "C:\\www\\node\\globalredex\\src\\components\\home\\OurServices.tsx");
import(/* webpackMode: "eager" */ "C:\\www\\node\\globalredex\\src\\components\\layout\\Footer.tsx");
import(/* webpackMode: "eager" */ "C:\\www\\node\\globalredex\\src\\components\\layout\\Navbar.tsx");
import(/* webpackMode: "eager" */ "C:\\www\\node\\globalredex\\src\\components\\layout\\Sidebar.tsx");
import(/* webpackMode: "eager" */ "C:\\www\\node\\globalredex\\src\\components\\products\\ProductOverview.tsx");
import(/* webpackMode: "eager" */ "C:\\www\\node\\globalredex\\src\\components\\reusable\\ImageSlider.tsx");
import(/* webpackMode: "eager" */ "C:\\www\\node\\globalredex\\src\\components\\reusable\\InfoCard.tsx");
import(/* webpackMode: "eager" */ "C:\\www\\node\\globalredex\\src\\components\\reusable\\ProductCard.tsx");
import(/* webpackMode: "eager" */ "C:\\www\\node\\globalredex\\src\\components\\reusable\\ProductModal.tsx");
import(/* webpackMode: "eager" */ "C:\\www\\node\\globalredex\\src\\components\\reusable\\ProductSection.tsx");
import(/* webpackMode: "eager" */ "C:\\www\\node\\globalredex\\src\\components\\reusable\\ReusableSlider.tsx");
import(/* webpackMode: "eager" */ "C:\\www\\node\\globalredex\\src\\styles\\CategoryProduct.css")